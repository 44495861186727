import ParseContent from 'components/shared/ParseContent'
import React from 'react'
import styled from 'styled-components'
import Blogik, { BlogConsumer, BlogButton } from 'components/shared/Blogik'

// Components
import Image from 'components/shared/ImageGatsby'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

// SVG
import { Link } from 'gatsby'

const StyledImage = styled(Image)`
  @media (max-width: 991px) {
    height: 250px;
  }

  @media (min-width: 992px) {
    height: 350px;
  }

  border-top-left-radius: 35px;
`

const ExcerptWrapper = styled.div`
  bottom: 100px;

  @media (max-width: 992px) {
    bottom: 0;
  }
`

const ExcerptContent = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
`

const Excerpt = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size.base};
    color: ${(props) => props.theme.color.text.dark};
    line-height: ${(props) => props.theme.font.size[20]};
  }

  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (min-width: 1200px) {
      -webkit-line-clamp: 3;
    }

    @media (max-width: 1199px) {
      -webkit-line-clamp: 4;
    }
  }
`

const NoCategoryMessage = styled.h3`
  font-size: ${(props) => props.theme.font.size[21]};
  font-weight: ${(props) => props.theme.font.weight.s};
`

interface ProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Projects
}

const Projects: React.FC<ProjectsProps> = ({ fields }) => (
  <div className="container mt-lg-n5 mb-lg-5 py-5 py-lg-0">
    <div className="row justify-content-center">
      <div>
        <Blogik
          postType="projects"
          settings={{
            id: 'blog',
          }}
        >
          {fields ? (
            <div>
              <BlogGrid projectFields={fields} />
            </div>
          ) : (
            <h4>Geen projecten gevonden, kijk later nog eens.</h4>
          )}
        </Blogik>
      </div>
    </div>
  </div>
)

interface BlogGridProps {
  // eslint-disable-next-line
  projectFields: GatsbyTypes.WpPage_Flexcontent_Flex_Projects
}

const BlogGrid: React.FC<BlogGridProps> = ({ projectFields }) => (
  <BlogConsumer>
    {({ posts, showMoreButton, hasPosts }: any) => {
      if (hasPosts) {
        return (
          <>
            <div className="d-flex flex-wrap">
              {posts.map((post: any, index: number) => (
                <Project
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  fields={post}
                  projectFields={projectFields}
                />
              ))}
            </div>
            {showMoreButton && (
              <div className="mb-5 mt-5 mt-md-0 text-center">
                <ButtonDefault to="/" isCustom>
                  <BlogButton>laad meer projecten</BlogButton>
                </ButtonDefault>
              </div>
            )}
          </>
        )
      }
      return (
        <NoCategoryMessage className="text-center pb-5">
          Geen projecten gevonden in deze categorie
        </NoCategoryMessage>
      )
    }}
  </BlogConsumer>
)

interface ProjectProps {
  fields: any
  // eslint-disable-next-line
  projectFields: GatsbyTypes.WpPage_Flexcontent_Flex_Projects
}

const Project: React.FC<ProjectProps> = ({ fields }) => (
  <BlogConsumer>
    {({ categories, hasPosts }: any) => {
      if (hasPosts) {
        const selectedCategories: Array<any> = []

        fields.node.projectCategories.nodes.forEach((projectCategory: any) => {
          const currentCategory: any = categories.find(
            (edge: any) => edge.node.id === projectCategory.id
          )

          if (currentCategory) {
            selectedCategories.push(currentCategory)
          }
        })

        return (
          <div className="col-md-6 mb-4 mb-lg-0">
            <Link to={fields.node.uri}>
              <StyledImage
                image={fields.node.projectDetail.thumbnail}
                loading="lazy"
              />
            </Link>
            <div className="d-flex justify-content-center">
              <ExcerptWrapper className="row position-relative align-items-center justify-content-between mx-lg-5 mx-0">
                <ExcerptContent className="p-lg-5 p-4 col-12">
                  <Excerpt content={fields.node.projectDetail.excerpt} />
                </ExcerptContent>
              </ExcerptWrapper>
            </div>
          </div>
        )
      }
      return <h4>Geen projecten gevonden...</h4>
    }}
  </BlogConsumer>
)

export default Projects
